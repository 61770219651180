import { Component, OnDestroy, OnInit } from '@angular/core'
import { ActivatedRoute, Router } from '@angular/router'
import { MaterielSynthese } from '../../models/materiel'
import { DataSharedService } from '../../services/data-shared.service'
import { SyntheseService } from '../../services/synthese.service'
import * as dayjs from 'dayjs'
import { MaterielService } from '../../services/materiel.service'
import { Subscription } from 'rxjs'
import { ToastrService } from 'ngx-toastr'
import { downloadPdfFile } from '../../utils/utils'
import { PeriodDto } from '../../models/period-dto'
import { StatutLabelPipe } from '../../pipes/statut-label.pipe'

@Component({
  selector: 'lg-synthese',
  templateUrl: './synthese.component.html',
  styleUrls: ['./synthese.component.scss'],
})
export class SyntheseComponent implements OnInit, OnDestroy {
  materielsSynthese: MaterielSynthese[] = []
  months: string[] = []
  chantierCode: string
  loading = false
  period: PeriodDto
  selectedMonth: string
  subscription = new Subscription()

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private dataSharedService: DataSharedService,
    private syntheseService: SyntheseService,
    private toastr: ToastrService,
    private statutLabelPipe: StatutLabelPipe,
    public materielService: MaterielService
  ) {}

  ngOnInit(): void {
    this.chantierCode = this.route.snapshot.paramMap.get('chantierCode')

    this.loading = true
    this.syntheseService.getSynthesePeriod(this.chantierCode).subscribe({
      next: (period) => {
        if (period) {
          this.period = period
          let startDayjs = dayjs(period.start)
          const endDayjs = dayjs(period.end)

          while (startDayjs.isBefore(endDayjs)) {
            this.months.push(startDayjs.format('YYYY-MM'))
            startDayjs = startDayjs.add(1, 'month')
          }
          this.months.push(endDayjs.format('YYYY-MM'))
          this.selectedMonth = this.months.at(-1)

          this.getMaterielsSynthese()
        } else {
          // Pas de synthese periode (Pas de matériels sur le chantier)
          this.loading = false
        }
      },
      error: (err) => {
        if (err.status === 404) {
          this.period = null
          this.months.push(dayjs().format('YYYY-MM'))
          this.getMaterielsSynthese()
        }
        this.loading = false
      },
    })
  }

  ngOnDestroy() {
    this.subscription.unsubscribe()
  }

  getMaterielsSynthese() {
    this.syntheseService.getMaterielsSynthese(this.chantierCode, this.selectedMonth).subscribe({
      next: (materielsSynthese: MaterielSynthese[]) => {
        materielsSynthese.forEach((materielSynthese) => {
          if(materielSynthese.getStatut() ==='HISTORISE') {
            materielSynthese.nbJoursNonPointe = 0
          }
          // On construit le label du dernier événement
          if (materielSynthese.getStatut() === 'SUR_CHANTIER') {
            materielSynthese.lastEvenementWithDate = this.statutLabelPipe.transform(materielSynthese.getStatut())
          } else if (materielSynthese.evenements && materielSynthese.evenements.length > 0) {
            const lastEvenement = materielSynthese.getLastEvenement()
            const lastEvenementDateFormatted = dayjs(lastEvenement.date).format('DD/MM/YYYY')
            materielSynthese.lastEvenementWithDate = `${lastEvenement.type.nom} le ${lastEvenementDateFormatted}`
          }
        })
        this.materielsSynthese = materielsSynthese.sort(
          (a, b) => new Date(a.getDateReception()).getTime() - new Date(b.getDateReception()).getTime()
        )
        this.loading = false
      },
      error: (err) => {
        this.loading = false
      },
    })
  }

  downloadPdf(materielSynthese: MaterielSynthese) {
    materielSynthese.downloadingPdf = true

    this.materielService.downloadMaterielPdf(this.chantierCode, materielSynthese.id).subscribe({
      next: (response) => {
        downloadPdfFile(response)
        this.toastr.success('PDF téléchargé')
        materielSynthese.downloadingPdf = false
      },
      error: () => {
        this.toastr.error('Erreur lors du téléchargement du PDF')
        materielSynthese.downloadingPdf = false
      },
    })
  }

  openSuiviDesCoupsUrl() {
    window.open('https://reporting.leon-grosse.fr/sense/app/5115e711-af69-4536-ba9e-ba1651b633b9/overview','_blank')
  }
}
