<div class="d-flex justify-content-center py-5" *ngIf="loading">
  <div class="spinner-border text-primary spinner-lg" role="status">
    <span class="visually-hidden">Loading...</span>
  </div>
</div>
<div class="d-flex justify-content-center py-5" *ngIf="!loading && !period">
  <div class="row ">
    <div class="col-12 d-flex justify-content-center">
      Aucun matériel sur le chantier
    </div>
    <div class="col-12 pt-4 d-flex justify-content-center">
      <button type="button" class="btn btn-outline-secondary" (click)="openSuiviDesCoupsUrl()">
        Rapport de suivi des coûts
      </button>
    </div>
  </div>
</div>
<ng-container *ngIf="!loading && period">
  <div class="mb-3 row">
    <label for="mois" class="col-sm-2 col-form-label">Mois :</label>
    <div class="col-sm-6">
      <select *ngIf="period" id="mois" class="form-select" (change)="getMaterielsSynthese()" [(ngModel)]="selectedMonth"
              style="max-width: 400px;">
        <option *ngFor="let month of months" [value]="month">{{ month | date: 'MMMM yyyy' | titlecase }}</option>
      </select>
      <div *ngIf="!period">
        Aucun matériel sur ce chantier
      </div>
    </div>
    <div class="col-sm-4 justify-content-end d-flex">
      <button type="button" class="btn btn-outline-secondary" (click)="openSuiviDesCoupsUrl()">
        Rapport de suivi des coûts
      </button>
    </div>
  </div>

  <table id="table-synthese" class="table table-bordered table-hover" style="table-layout: fixed;">
    <thead>
    <tr>
      <th>N°CMD</th>
      <th>FOURN.</th>
      <th>MATERIEL</th>
      <th style="width: 50px">U.</th>
      <th style="width: 64px">Utilisé</th>
      <th style="width: 64px">Int.</th>
      <th style="width: 64px">Panne</th>
      <th style="width: 64px">Casse</th>
      <th style="width: 64px">Non pointé</th>
      <th style="width: 250px">Statut Actuel</th>
      <th>Compte-rendu</th>
    </tr>
    </thead>
    <tbody>
    <tr *ngFor="let materielSynthese of materielsSynthese">
      <td class="text-center text-truncate"
          [ngbTooltip]="materielSynthese.numCommande">{{ materielSynthese.numCommande }}
      </td>
      <td class="text-truncate" [ngbTooltip]="materielSynthese.locatier.nom">{{ materielSynthese.locatier.nom }}</td>
      <td class="text-truncate" [ngbTooltip]="materielSynthese.nom">{{ materielSynthese.nom }}</td>
      <td class="text-center">{{ materielSynthese.uniteTemps.charAt(0) }}</td>

      <td class="text-center">{{ materielSynthese.nbJoursUtilisation | jourSynthese }}</td>
      <td class="text-center">{{ materielSynthese.nbJoursIntemperie | jourSynthese }}</td>
      <td class="text-center">{{ materielSynthese.nbJoursPanne | jourSynthese }}</td>
      <td class="text-center">{{ materielSynthese.nbJoursCasse | jourSynthese }}</td>
      <td class="text-center"
          [class.text-white]="materielSynthese.getStatut() !== 'HISTORISE'"
          [class.bg-danger]="materielSynthese.getStatut() !== 'HISTORISE' && materielSynthese.nbJoursNonPointe"
          [class.bg-success]="materielSynthese.getStatut() !== 'HISTORISE' && !materielSynthese.nbJoursNonPointe">
        {{ materielSynthese.nbJoursNonPointe | jourSynthese }}
      </td>

      <td [ngbTooltip]="materielSynthese.getStatut() | statutLabel" class="text-center">
        {{ materielSynthese.lastEvenementWithDate }}
      </td>
      <td class="text-center py-1">
        <button type="button" class="btn btn-sm btn-primary" (click)="downloadPdf(materielSynthese)">
          <ng-container *ngIf="materielSynthese.downloadingPdf">
            <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
            <span class="visually-hidden">Loading...</span>
          </ng-container>
          <ng-container *ngIf="!materielSynthese.downloadingPdf">
            <i class="bi bi-file-earmark-pdf"></i>
          </ng-container>
        </button>
      </td>
    </tr>
    <tr *ngIf="materielsSynthese.length === 0">
      <td colspan="11" class="text-center">Aucun matériel à afficher</td>
    </tr>
    </tbody>
  </table>
</ng-container>
